import React, { useState, useEffect } from "react"
import DataTable from 'react-data-table-component'
import { plutoClient } from "../services/http"
import { PlutoAPIRoutes } from "../constants/pluto"
import { getSite } from "../services/utils";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComment, faHandPointRight, faTimes, faClipboardList } from '@fortawesome/free-solid-svg-icons'
import swal from 'sweetalert';
import { updatePlacement } from "../requests/placements"
import { Loading } from "./utils";
import { ExportCSV } from "../libraries/export";

const selectProps = { indeterminate: (isIndeterminate) => isIndeterminate };

const Placements = (props) => {
  const [placements, setPlacements] = useState([]);
  const [placementsLoading, setPlacementsLoading] = useState(true);
  const [placementsError, setPlacementsError] = useState('');

  const site = getSite();

  const getPlacements = async () => {
    await plutoClient(
      PlutoAPIRoutes.EMPLOYABILITY_PLACEMENTS,
      'GET',
      {},
      { authHeader: true, siteId: true, headers: {} },
      true,
    ).then(
      async (response) => {
        setPlacements(response);
      },
      async (error) => {
        setPlacementsError('Oops, we are unable to show you this right now');
      },
    );
  }

  useEffect(() => {
    const fetchData = async () => {
      await getPlacements()
    }

    fetchData().then(() => {
      setPlacementsLoading(false)
    })
  }, []);

  const startPlacement = (id) => {
    swal({
      title: "Start Placement?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then(async (startPlacement) => {
        if (startPlacement) {
          const placed = await updatePlacement({ has_progressed: true, placement_status: 'S' }, id)

          if (placed.success) {
            getPlacements()

            swal("This placement has been started!", {
              icon: "success",
            });
          } else {
            swal("Oops, there was a problem, please try again later", {
              icon: "error",
            });
          }
        }
      });
  }

  const markUnsuccessful = (id) => {
    swal({
      title: "Mark unsuccessful?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then(async (markUnsuccessful) => {
        if (markUnsuccessful) {

          const marked = await updatePlacement({ placement_status: 'U' }, id)

          if (marked.success) {
            getPlacements()

            swal("Student has been marked as unsuccessful!", {
              icon: "success",
            });
          } else {
            swal("Oops, there was a problem, please try again later", {
              icon: "error",
            });
          }
        }
      });
  }

  const submitFeedback = (id) => {
    swal({
      text: 'Leave feedback of student',
      content: "input",
      button: {
        text: "Submit",
        closeModal: false,
      },
    })
      .then(async (submission) => {
        if (!submission) {
          swal.stopLoading();
          swal.close();

          return
        };

        const response = await updatePlacement({ feedback: submission }, id)

        if (response.success) {
          getPlacements()

          swal("Feedback has been submitted", {
            icon: "success",
          });
        } else {
          swal("Oops, there was a problem, please try again later", {
            icon: "error",
          });
        }
      });
  }

  const showFeedback = (text) => {
    swal('Feedback: ' + text);
  }

  const showAdditionalInfo = (info) => {
    swal({
      title: 'Additional Information',
      text: info,
      button: {
        text: "Close",
        closeModal: true,
      }
    });
  }
  
  if (placementsLoading) {
    return (
      <>
        <Loading />
      </>
    )
  }

  if (placementsError) {
    return (
      <div class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
        <strong class="font-bold">Oops!</strong>
        <span class="block sm:inline">{placementsError}</span>
      </div>
    )
  }
  return (
    <>
      <div className="bg-white p-5 rounded-lg mt-5">
        <h1 className="text-xl font-bold mb-5">Placements {placements.length > 0 && <ExportCSV csvData={placements.map(item => item = {...item['member'], ...{opportunity_id: item['opportunity']['id'], opportunity_name: item['opportunity']['name']}})} fileName="placement-contact-details" />}</h1>
        <DataTable
          responsive
          striped
          pagination
          selectableRowsComponentProps={selectProps}
          columns={[
            {
              name: 'Name',
              selector: row => row.member.first_name + ' ' + row.member.last_name,
              width: "16.6%",
            },
            {
              name: 'Email',
              cell: row => row.member.organisation_email,
              width: "16.6%",
            },
            {
              name: 'Opportunity',
              cell: row => row.opportunity.name,
              width: "16.6%",
            },
            {
              name: 'Interest Date',
              cell: row => <span className={`text-xs font-semibold inline-block py-1 px-2 rounded last:mr-0 mr-1 text-gray-600 bg-gray-200`}>{row.interest.date}</span>,
              width: "16.6%",
              sortable: true,
              sortFunction: (a, b) => {

                a = Date.parse(a.interest.date);
                b = Date.parse(b.interest.date);
                
                if (a > b) {
                  return 1;
                }
              
                if (b > a) {
                  return -1;
                }
              
                return 0;
              }
            },
            {
              name: 'Started Placement?',
              cell: row => <span className={`text-xs font-semibold inline-block py-1 px-2 rounded last:mr-0 mr-1 ${row.has_progressed ? 'text-green-600 bg-green-200' : 'text-red-600 bg-red-200'}`}>{row.has_progressed ? 'Yes' : 'No'}</span>,
              width: "16.6%",
            },
            {
              name: 'Actions',
              style: {
                display: 'flex',
                justifyContent: 'end'
              },
              cell: row =>
                <>
                  {row.interest && row.interest.interest_text &&
                    <button
                      style={{backgroundColor: site.primary_colour ?? '#fff'}}
                      className="tooltip text-white font-small py-2 px-4 rounded mr-2"
                      onClick={() => showAdditionalInfo(row.interest.interest_text)}>
                        <FontAwesomeIcon icon={faClipboardList} />
                        <span className="tooltiptext">View Info</span>
                      </button>
                  }
                  <button 
                    style={{backgroundColor: site.primary_colour ?? '#fff'}}
                    className="tooltip text-white font-small py-2 px-4 rounded mr-2"
                    data-tooltip-target="tooltip-default"
                    onClick={() => row.feedback === null ? submitFeedback(row.id) : showFeedback(row.feedback)}
                  >
                    <FontAwesomeIcon icon={faComment} />
                    <span className="tooltiptext">{row.feedback === null ? "Submit Comment" : "Show Comment"}</span>
                  </button>
                  {!row.has_progressed &&
                    <button
                      style={{ backgroundColor: site.primary_colour ?? '#fff' }}
                      className="tooltip text-white font-small py-2 px-4 rounded mr-2"
                      onClick={() => startPlacement(row.id)}>
                      <FontAwesomeIcon icon={faHandPointRight} />
                      <span className="tooltiptext">Start Placement</span>
                    </button>
                  }
                  {!row.has_progressed &&
                    <button
                      style={{ backgroundColor: site.primary_colour ?? '#fff' }}
                      className="tooltip text-white font-small py-2 px-4 rounded"
                      onClick={() => markUnsuccessful(row.id)}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                      <span className="tooltiptext">Mark Unsuccessful</span>
                    </button>

                  }

                </>,
              button: true,
              width: "16.6%",
            }
          ]}
          data={placements}
        />
      </div>
    </>
  );
}

export default Placements